import React from 'react';
import { ListItem, Tag, TagLabel, Text, useTheme } from '@chakra-ui/react';
import { ChamaMember, ChamaMemberRole, ChamaRule, User } from '@bitsacco/types';
import { FetchHeadshot } from '../Headshot';

interface ChamaMemberInfoProps extends ChamaMember {
  user: User;
}

export const ChamaMemberInfo = React.memo(function ChamaMemberInfo({
  id,
  role,
  phone,
  user,
}: ChamaMemberInfoProps) {
  const theme = useTheme();

  const self = user.id === id;

  const getLabel = () => {
    const admin = role === ChamaMemberRole.Admin;

    if (self && admin) {
      return 'You ( admin )';
    }

    if (self) {
      return 'You';
    }

    if (admin) {
      return `${phone} ( admin )`;
    }

    return phone;
  };

  return (
    <Tag
      borderRadius={20}
      borderColor={theme.colors.gray[300]}
      borderWidth={2}
      px={3}
      py={2}
    >
      <FetchHeadshot user={user} id={id} size='sm' ml={-1} mr={2} />
      <TagLabel fontSize={'bold'}>{getLabel()}</TagLabel>
    </Tag>
  );
});

export const ChamaRuleInfo = React.memo(function ChamaRuleInfo({
  rule,
  description,
}: ChamaRule) {
  return (
    <ListItem>
      <Text fontSize='md'>{rule}</Text>
      {description && <Text fontSize='sm'>{description}</Text>}
    </ListItem>
  );
});
