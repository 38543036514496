import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  Stack,
  Text,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { UpdateUser, User } from '@bitsacco/types';
import {
  useAuth,
  Headshot,
  useApi,
  AccountNostr,
  AccountProfile,
  AccountSecurity,
  AccountAddress,
} from '../components';
import { copyToClipboard, getProfileLabel, isValidAddress } from '../utils';
import { TOAST_TIMEOUT_MS } from '../configs';

export const Account = React.memo(function Accoint() {
  const { bitsacco } = useApi();
  const { user, login, logout } = useAuth();

  const { colorMode } = useColorMode();

  const [edit, setEdit] = useState(false);
  const [updateError, setUpdateError] = useState<string>('');

  const [pname, setPname] = useState<string>(user?.profile?.name || '');
  const [paddress, setPaddress] = useState(user?.profile?.address || '');

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const cancelEdit = useCallback(() => {
    setEdit(false);
  }, [setEdit]);

  const saveEdits = useCallback(() => {
    if (!user) {
      return;
    }

    const { name, address } = user.profile || {};

    if (name && name === pname && address && address === paddress) {
      return setEdit(false);
    }

    const profile = { ...user.profile, name: pname, address: paddress };
    const nostr = user.nostr;

    (async () => {
      try {
        setLoading(true);
        const updated = await bitsacco.request<User, UpdateUser>(
          'PATCH',
          '/user/update',
          {
            phone: user.phone,
            updates: {
              pinHash: user.pinHash,
              profile,
              nostr,
            },
          }
        );

        if (updated) {
          setUpdateError('');
          setEdit(false);
          setLoading(false);

          toast({
            title: 'Success',
            description: `Updated account info.`,
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });

          return login(updated, '/account');
        }

        setLoading(false);
        throw 'failed to update account info';
      } catch (e) {
        setUpdateError(`${e}`);
        setLoading(false);
      }
    })();
  }, [bitsacco, user, pname, paddress, toast, login, setEdit, setUpdateError]);

  const copyText = useCallback(
    (name: string, value: string) => {
      (async () => {
        try {
          await copyToClipboard(value);
          toast({
            title: 'Success',
            description: `Copied ${name} to clipboard.`,
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        } catch (e) {
          console.error(e);
        }
      })();
    },
    [toast]
  );

  const bgBox = colorMode === 'dark' ? 'gray.800' : '';
  const bdBox = colorMode === 'dark' ? 'gray.800' : '';

  return (
    <Box bg={colorMode === 'dark' ? 'gray.800' : ''} minH='100vh' p={4}>
      {user && (
        <Stack spacing={6} maxW='xl' mx='auto' pt={8}>
          <Flex align='center'>
            <Skeleton size='lg' isLoaded={!!user}>
              <Headshot user={user} size='lg' />
            </Skeleton>
            <Flex align='center' justify='center' gap={2} ml={4}>
              <Text fontSize='xl' fontWeight='bold'>
                {getProfileLabel(user)}
              </Text>
              {!edit && (
                <IconButton
                  aria-label='Copy Profile Label'
                  icon={<CopyIcon />}
                  onClick={() =>
                    copyText('profile label', getProfileLabel(user))
                  }
                />
              )}
            </Flex>
          </Flex>
          <Flex justify='space-between'>
            {edit ? (
              <Button colorScheme='teal' variant='outline' onClick={cancelEdit}>
                Cancel
              </Button>
            ) : (
              <Button colorScheme='teal' onClick={() => setEdit(true)}>
                Edit
              </Button>
            )}
            <Button colorScheme='teal' onClick={logout} variant='outline'>
              Logout
            </Button>
          </Flex>

          {updateError && (
            <Text color='red.500' textAlign='center'>
              {updateError}
            </Text>
          )}

          <Divider orientation='horizontal' borderWidth='2px' />

          <AccountProfile
            edit={edit}
            phone={user.phone}
            name={edit ? pname : user.profile?.name}
            proposeName={setPname}
            bgBox={bgBox}
            bdBox={bdBox}
          />

          <AccountAddress
            edit={edit && !isValidAddress(user.profile?.address)}
            address={edit ? paddress : user.profile?.address}
            proposeAddress={setPaddress}
            copyAddress={() => copyText('address', user.profile?.address || '')}
            bgBox={bgBox}
            bdBox={bdBox}
          />

          <AccountSecurity phone={user.phone} edit={edit} />

          <AccountNostr
            edit={edit}
            {...(user.nostr || {})}
            bgBox={bgBox}
            bdBox={bdBox}
          />

          {edit && (
            <>
              {loading ? (
                <CircularProgress isIndeterminate />
              ) : (
                <Button colorScheme='teal' onClick={saveEdits} disabled={true}>
                  Save
                </Button>
              )}
            </>
          )}

          <Divider orientation='horizontal' borderWidth='2px' />
        </Stack>
      )}
    </Box>
  );
});
