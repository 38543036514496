export const FEDERATION_ID = process.env.REACT_APP_BS_FEDERATION_ID || '';

export const GATEWAY_ID = process.env.REACT_APP_BS_GATEWAY_ID || '';

export const CLIENTD_BASE_URL =
  process.env.REACT_APP_CHAMA_CLIENTD_BASE_URL || '';

export const CLIENTD_PASS = process.env.REACT_APP_CHAMA_CLIENTD_PASS || '';

export const INVITE_CODE =
  'fed11qgqr6wmhwden5te0v9cxjtt90p68ymmkv4e8gety94jkzun5dqkkkam0w4m8vmn4weskxu3nw95n27r3ve6juamwv4u8gtnpwpcz7qqpyzkdrcqdqr70ahaay6psa6acp00g47xf2wns5c2l024k2n5lqkyrsp5tdec';

export const FEDI_BRAVO_ANDROID_DOWNLOAD_URL =
  'https://play.google.com/store/apps/details?id=com.fedi';

export const FEDI_BRAVO_IOS_DOWNLOAD_URL =
  'https://apps.apple.com/us/app/fedi-alpha/id6448916281';

export const FEDI_BRAVO_APK_DOWNLOAD_URL =
  'https://github.com/fedibtc/fedi-alpha/releases';

export const MUTINY_URL = 'https://mutinynet.com';

export const FEDI_ALPHA_URL = 'https://alpha.fedi.xyz/';

export const BITSACCO_DOMAIN = 'bitsacco.com';

export const BITSACCO_URL = `https://${BITSACCO_DOMAIN}`;

export const BITSACCO_BLOG_URL = `https://blog.${BITSACCO_DOMAIN}`;

export enum PhoneRegionCode {
  Kenya = 'KE',
}

export const TOAST_TIMEOUT_MS = 2000;
export const DEBOUNCE_DELAY_MS = 200;
