import React from 'react';
import {
  Flex,
  Text,
  IconButton,
  useTheme,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

export const Footer = React.memo(function Footer() {
  const { colorMode, toggleColorMode } = useColorMode();
  const theme = useTheme();

  const bg = useColorModeValue(theme.colors.gray[50], theme.colors.gray[700]);

  return (
    <Flex
      direction={{ base: 'column-reverse', sm: 'row' }}
      align='center'
      justify='space-between'
      w='100vw'
      maxW='1440px'
      px={{ base: '12px', md: '20px', lg: '40px', xl: '100px' }}
      height={{ base: '64px', md: '100px' }}
      bg={bg}
    >
      <Text>© BITSACCO</Text>
      <IconButton
        aria-label='Toggle theme'
        icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
        onClick={toggleColorMode}
      />
    </Flex>
  );
});
