import React, { useCallback } from 'react';
import {
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Center,
  IconButton,
} from '@chakra-ui/react';
import { TimeIcon, ChatIcon } from '@chakra-ui/icons';
import { Chama, ChamaTxState, ChamaTxType, User } from '@bitsacco/types';
import { FedimintClient } from 'fedimint-ts';

import {
  recordChamaTransaction,
  updateChamaMemberBalance,
} from '../../services';
import { DepositClient, DepositTarget, DepositModal } from '../DepositModal';
import {
  WithdrawClient,
  WithdrawTarget,
  RequestWithdrawModal,
} from '../withdraw';
import { useApi, useFx } from '../Providers';
import { ChamaCardActivity } from './ChamaCardActivity';
import { ChamaCardMain } from './ChamaCardMain';
import { ChamaCardChat } from './ChamaCardChat';

interface ChamaCardProps {
  user: User;
  chama: Chama;
  chamaClient: FedimintClient;
  setFetchChamas: (fetchChamas: boolean) => void;
}

enum CardView {
  Main,
  Activity,
  Chat,
}

export const ChamaCard = React.memo(function ChamaCard({
  user,
  chama,
  chamaClient,
  setFetchChamas,
}: ChamaCardProps) {
  const { bitsacco } = useApi();
  const { milliSatsToKes } = useFx();
  const { id: chamaId, name: chamaName, description } = chama;

  const [view, setView] = React.useState<CardView>(CardView.Main);

  const {
    isOpen: showDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useDisclosure();

  const {
    isOpen: showWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  const depositClient: DepositClient = {
    createInvoice: chamaClient.lightning.createInvoice,
    awaitInvoice: chamaClient.lightning.awaitInvoice,
  };

  const withdrawClient: WithdrawClient = {
    lnPay: chamaClient.lightning.pay,
    awaitLnPay: chamaClient.lightning.awaitInvoice,
  };

  const transactionTarget: DepositTarget | WithdrawTarget = {
    id: chamaId,
    name: chamaName,
    phone: user.phone,
  };

  const onTransactionComplete = useCallback(
    (txid: string, amount: number, type: 'deposit' | 'withdraw') => {
      console.log(`Transaction complete: ${amount} msats`);

      const fx = milliSatsToKes(amount);

      switch (type) {
        case 'deposit':
          updateChamaMemberBalance(
            bitsacco,
            chama,
            user,
            amount /* deposit */,
            0 /* withdrawal */,
            0 /* penalty */
          ).then(() => setFetchChamas(true));
          recordChamaTransaction(bitsacco, chama, user, {
            id: txid,
            type: ChamaTxType.Deposit,
            amount,
            state: ChamaTxState.Complete,
            meta: {
              chama: chama.id,
              user: user.id,
              description: `Deposit of ${fx.n} ${fx.r}`,
              timestamp: new Date(),
            },
            approvals: [],
          });
          break;
        case 'withdraw':
          updateChamaMemberBalance(
            bitsacco,
            chama,
            user,
            0 /* deposit */,
            amount /* withdrawal */,
            0 /* penalty */
          ).then(() => setFetchChamas(true));
          recordChamaTransaction(bitsacco, chama, user, {
            id: txid,
            type: ChamaTxType.Withdrawal,
            amount,
            state: ChamaTxState.Pending,
            meta: {
              chama: chama.id,
              user: user.id,
              description: `Withdrawal of ${fx.n} ${fx.r}`,
              timestamp: new Date(),
            },
            approvals: [],
          });
          break;
      }

      console.log(`Transaction ${txid} recorded`);
      console.log(`Updated chama balance`);
    },
    [bitsacco, chama, user, setFetchChamas, milliSatsToKes]
  );

  const getCardHeader = useCallback(() => {
    return (
      <Flex
        flexDirection='row'
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        justifyContent='space-between'
      >
        <Flex
          gap={2}
          alignItems='center'
          flexDirection='row'
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexGrow={1}
        >
          <Heading size='md'>{chamaName}</Heading>
          <Text>{description}</Text>
        </Flex>
        <Flex
          gap={2}
          alignItems='end'
          flexDirection='row'
          my={{ base: 2, lg: 0 }}
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        >
          <IconButton
            aria-label='show chama activity'
            icon={<TimeIcon />}
            onClick={() =>
              setView(
                view !== CardView.Activity ? CardView.Activity : CardView.Main
              )
            }
          />
          <IconButton
            aria-label='show chama chat'
            icon={<ChatIcon />}
            onClick={() =>
              setView(view === CardView.Chat ? CardView.Main : CardView.Chat)
            }
          />
        </Flex>
      </Flex>
    );
  }, [chamaName, description, view, setView]);

  const getCardBody = useCallback(() => {
    switch (view) {
      case CardView.Main:
        return <ChamaCardMain user={user} chama={chama} rules={chama.rules} />;
      case CardView.Activity:
        return <ChamaCardActivity user={user} chamaId={chamaId} />;
      case CardView.Chat:
        return <ChamaCardChat user={user} chama={chama} />;
    }
  }, [user, chama, chamaId, view]);

  return (
    <>
      <Card variant='elevated' colorScheme='teal' boxShadow='dark-lg'>
        <CardHeader>{getCardHeader()}</CardHeader>

        <Center>
          <Divider orientation='horizontal' border={'1px solid teal'} />
        </Center>

        <CardBody>{getCardBody()}</CardBody>

        <Center>
          <Divider orientation='horizontal' border={'1px solid teal'} />
        </Center>

        <CardFooter>
          <ButtonGroup
            w='100%'
            display='flex'
            flexDirection='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            justifyContent={{ base: 'center', lg: 'start' }}
            gap={'4'}
            spacing={{ base: '0', lg: '4' }}
          >
            <Button
              height={'45px'}
              minW={'150px'}
              flexGrow={1}
              variant='solid'
              colorScheme='teal'
              onClick={onOpenDepositModal}
            >
              Deposit Funds
            </Button>
            <Button
              height={'45px'}
              minW={'150px'}
              flexGrow={1}
              variant='solid'
              colorScheme='teal'
              onClick={onOpenWithdrawModal}
              m={0}
            >
              Request Withdrawal
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
      <DepositModal
        depositClient={depositClient}
        depositTarget={transactionTarget}
        isOpen={showDepositModal}
        onClose={onCloseDepositModal}
        onTransactionComplete={(txid: string, amount: number) =>
          onTransactionComplete(txid, amount, 'deposit')
        }
      />
      <RequestWithdrawModal
        chama={chama}
        user={user}
        isOpen={showWithdrawModal}
        onClose={() => {
          setFetchChamas(true);
          onCloseWithdrawModal();
        }}
      />
    </>
  );
});
