import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Select,
  Stack,
  Text,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { ChamaRules } from '@bitsacco/types';

interface ChamaTemplateBuilderProps {
  chamaRules: ChamaRules;
  setChamaRules: (rules: ChamaRules) => void;
}

enum ChamaTemplate {
  RotatingSavings = 'RotatingSavings',
  InvestToTarget = 'InvestToTarget',
}

export const ChamaTemplateBuilder = React.memo(function ChamaTemplateBuilder({
  chamaRules,
  setChamaRules,
}: ChamaTemplateBuilderProps) {
  const [template, setTemplate] = useState<ChamaTemplate>(
    ChamaTemplate.RotatingSavings
  );

  const renderRulePicker = useCallback(() => {
    switch (template) {
      case ChamaTemplate.RotatingSavings:
        return (
          <RulePicker
            rules={{
              Contribution: {
                rule: 'Contribution',
                description:
                  'Members contribute 100 Kshs in Bitcoin every week',
              },
              Penalty: {
                rule: 'Penalty',
                description:
                  'A member will pay a penalty of 20 Kshs for missing a contribution',
              },
              Withdrawal: {
                rule: 'Rotation',
                description:
                  'A member receives the total contribution every week',
              },
            }}
            selected={chamaRules}
            updateRules={(selectedRules) => setChamaRules(selectedRules)}
          />
        );
      case ChamaTemplate.InvestToTarget:
        return (
          <RulePicker
            rules={{
              Contribution: {
                rule: 'Contribution',
                description:
                  'Members contribute 100 Kshs in Bitcoin every week',
              },
              Target: {
                rule: 'Target',
                description:
                  'Each member saves to a target of 1000 Kshs in Bitcoin',
              },
              Penalty: {
                rule: 'Penalty',
                description: 'Penalty for missing a contribution',
              },
              Withdrawal: {
                rule: 'Rotation',
                description:
                  'Each member receives their total contribution after they reach the target',
              },
            }}
            selected={chamaRules}
            updateRules={(selectedRules) => setChamaRules(selectedRules)}
          />
        );
      default:
        return <Text>unknown Chama template selected</Text>;
    }
  }, [template, chamaRules, setChamaRules]);

  return (
    <Flex direction='column' align='center' gap={4}>
      <Select
        size='md'
        value={template}
        onChange={(e) => {
          setTemplate(e.target.value as unknown as ChamaTemplate);
          setChamaRules({});
        }}
      >
        <option value={ChamaTemplate.RotatingSavings}>
          Rotating Savings (merry go round)
        </option>
        <option value={ChamaTemplate.InvestToTarget}>
          Invest to Target (save to a goal)
        </option>
      </Select>
      {renderRulePicker()}
    </Flex>
  );
});

interface RulePickerOptions {
  rules: ChamaRules;
  selected: ChamaRules;
  updateRules: (selectedRules: ChamaRules) => void;
}

const RulePicker = function RulePicker({
  rules,
  selected,
  updateRules,
}: RulePickerOptions) {
  const { value, getCheckboxProps, setValue } = useCheckboxGroup({
    onChange: (values: string[]) => {
      const selectedRules = Object.fromEntries(
        Object.entries(rules).filter((entry) => values.includes(entry[1].rule))
      );
      updateRules(selectedRules as ChamaRules);
    },
    defaultValue: Object.values(selected).map((cr) => cr.rule),
  });

  useEffect(() => {
    setValue(Object.values(selected).map((cr) => cr.rule));
  }, [selected, setValue]);

  const renderCheckboxes = useCallback(() => {
    return Object.values(rules).map((cr) => (
      <Checkbox key={cr.rule} {...getCheckboxProps({ value: cr.rule })}>
        <Flex direction='column'>
          <Text>{cr.rule}</Text>
          <Text size='xs'>{cr.description}</Text>
        </Flex>
      </Checkbox>
    ));
  }, [rules, getCheckboxProps]);

  return (
    <Box borderWidth='1px' borderRadius='md' p={4}>
      <CheckboxGroup colorScheme='teal' value={value}>
        <Stack spacing={2}>{renderCheckboxes()}</Stack>
      </CheckboxGroup>
    </Box>
  );
};
