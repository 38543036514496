import React, { useCallback } from 'react';
import {
  User,
  Chama,
  ChamaRules,
  Currency,
  ChamaMembers,
  ChamaMemberRole,
} from '@bitsacco/types';
import {
  useDisclosure,
  Flex,
  Heading,
  ListItem,
  Center,
  Divider,
  Button,
  UnorderedList,
  Text,
} from '@chakra-ui/react';
import { getUserBalanceMsats, getGroupBalanceMsats } from '../../services';
import { useFx } from '../Providers';
import { ChamaRuleInfo, ChamaMemberInfo } from './ChamaInfo';
import { InviteModal } from './InviteModal';

interface ChamaCardMainProps {
  user: User;
  chama: Chama;
  rules: ChamaRules;
}

export const ChamaCardMain = React.memo(function ChamaCardMain({
  user,
  chama,
  rules,
}: ChamaCardMainProps) {
  const { milliSatsToKes } = useFx();
  const {
    isOpen: showInviteModal,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useDisclosure();

  const renderUserBalance = useCallback(() => {
    const bMsats = getUserBalanceMsats(chama.members, user.id);
    const fx = milliSatsToKes(bMsats);

    return (
      <Flex direction='column' flexGrow={1} w={{ base: '100%', md: 'auto' }}>
        <Heading size='xs'>My Funds</Heading>
        {fx.r === Currency.KES && (
          <Text pt='2'>
            <strong>{fx.n}</strong> KES
          </Text>
        )}
        <Text pt='2'>
          B <strong>{(bMsats / 1000).toFixed(2)}</strong> sats
        </Text>
      </Flex>
    );
  }, [user, chama, milliSatsToKes]);

  const renderGroupBalance = useCallback(() => {
    const bMsats = getGroupBalanceMsats(chama.members);
    const fx = milliSatsToKes(bMsats);

    return (
      <Flex direction='column' flexGrow={1} w={{ base: '100%', md: 'auto' }}>
        <Heading size='xs'>Group Funds</Heading>
        {fx.r === Currency.KES && (
          <Text pt='2'>
            <strong>{fx.n}</strong> KES
          </Text>
        )}
        <Text pt='2'>
          B <strong>{(bMsats / 1000).toFixed(2)}</strong> sats
        </Text>
      </Flex>
    );
  }, [chama, milliSatsToKes]);

  const renderChamaRules = useCallback(() => {
    const entries = Object.entries(rules);

    if (entries.length < 1) {
      return <ListItem>no explicit rules set</ListItem>;
    }

    return entries.map(([idx, rule]) => <ChamaRuleInfo key={idx} {...rule} />);
  }, [rules]);

  return (
    <>
      <Flex direction='column' gap={4}>
        <Flex
          flexDirection='column'
          px={{ base: '', md: '3' }}
          gap={{ base: '6', lg: '6' }}
        >
          <Heading size='sm' textTransform='uppercase'>
            Funds
          </Heading>
          <Flex
            display='flex'
            flexDirection='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            justifyContent='space-between'
            px={{ base: '', md: '3' }}
            gap={{ base: '6', lg: '6' }}
          >
            {renderGroupBalance()}
            <Center height={{ base: '0%', sm: '80px' }}>
              <Divider orientation='vertical' />
            </Center>
            {renderUserBalance()}
          </Flex>
        </Flex>

        <Divider orientation='horizontal' />

        <Flex direction='column' gap={4} flexGrow={1}>
          <Flex
            gap={'4'}
            direction='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            align='center'
            alignItems={'center'}
            justifyItems={'center'}
            justify='space-between'
          >
            <Heading size='sm' textTransform='uppercase'>
              Members
            </Heading>
            {isAdmin(chama.members, user.id) && (
              <Button
                variant='outline'
                colorScheme='green'
                height={'35px'}
                onClick={onOpenInviteModal}
              >
                + invite
              </Button>
            )}
          </Flex>

          <Flex
            pt={2}
            flexDirection='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            gap={2}
          >
            {Object.entries(chama.members).map(([idx, member]) => {
              return <ChamaMemberInfo key={idx} {...member} user={user} />;
            })}
          </Flex>
          <Divider orientation='horizontal' />
          <Heading size='sm' textTransform='uppercase'>
            Rules
          </Heading>
          <UnorderedList gap={2}>{renderChamaRules()}</UnorderedList>
        </Flex>
      </Flex>
      <InviteModal
        chama={chama}
        isOpen={showInviteModal}
        onClose={onCloseInviteModal}
      />
    </>
  );
});

export const isAdmin = (chamaMembers: ChamaMembers, userId: string) => {
  const member = chamaMembers[userId];
  return member && member.role === ChamaMemberRole.Admin;
};
