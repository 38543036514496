import React from 'react';
import { Text, Button, ButtonGroup, Flex, Link, Box } from '@chakra-ui/react';
import QRCode from 'qrcode.react';

import {
  FEDI_BRAVO_ANDROID_DOWNLOAD_URL,
  FEDI_BRAVO_APK_DOWNLOAD_URL,
  FEDI_BRAVO_IOS_DOWNLOAD_URL,
  INVITE_CODE,
} from '../configs';
import { SectionTemplate } from './SectionTemplate';

export const AccessFedi = React.memo(function AccessFedi(): JSX.Element {
  return (
    <SectionTemplate
      heading='GET BITSACCO MOBILE'
      subheading='If you prefer a mobile experience, download Fedi and scan the QR
            code below to join Bitsacco'
    >
      <Flex
        direction='row'
        wrap={{ base: 'wrap', lg: 'nowrap' }}
        align={{ base: 'flex-end', lg: 'start' }}
        gap={{ base: '0', md: '5', lg: '10' }}
        mt={{ base: '0', md: '5', lg: '10' }}
        h='100%'
        w='100%'
        p='5'
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          w={{ base: '100%', lg: '' }}
        >
          <Box
            backgroundColor='#FFFFFF'
            border={'3px solid teal'}
            rounded={'12px'}
            padding='12px'
          >
            <QRCode
              value={INVITE_CODE}
              size={256}
              level='H'
              renderAs='canvas'
              fgColor='#000000'
              bgColor='#ffffff'
              imageSettings={{
                src: 'https://bitsacco.com/apple-touch-icon.png',
                height: 48,
                width: 48,
                excavate: true,
              }}
              style={{ borderRadius: '16px' }}
              includeMargin={true}
            />
          </Box>
        </Box>
        <Flex
          direction='column'
          basis={{ base: '100%', lg: '' }}
          justifyContent='space-between'
        >
          <Flex
            direction='column'
            justify={{ base: 'center', lg: 'start' }}
            align={{ base: 'center', lg: 'start' }}
            gap='4'
          >
            <Text
              fontSize='3xl'
              fontFamily='body'
              mb={4}
              pt={{ base: '4', lg: '0' }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              USE FEDI WALLET
            </Text>
            <Text
              textAlign={{ base: 'center', lg: 'left' }}
              maxW={'500px'}
              fontFamily={'body'}
              fontSize={{ base: '16px', lg: '18px' }}
            >
              We have partnered with <strong>Fedi Bravo</strong> to bring you
              Bitsacco experience in a private and secure wallet for your
              Bitcoin.
            </Text>
            <Text
              textAlign={{ base: 'center', lg: 'left' }}
              maxW={'500px'}
              fontFamily={'body'}
              fontSize={{ base: '16px', lg: '18px' }}
            >
              Not yet on <strong>Fedi Bravo</strong>? Download the app now.
            </Text>
          </Flex>
          <ButtonGroup
            display='flex'
            flexDirection='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            justifyContent={{ base: 'center', lg: 'start' }}
            gap={{ base: '3', lg: '6' }}
            fontFamily='body'
            pt='8'
          >
            <Link href={FEDI_BRAVO_ANDROID_DOWNLOAD_URL} isExternal>
              <Button variant='solid' colorScheme='teal' minW={'124px'}>
                Android
              </Button>
            </Link>
            <Link href={FEDI_BRAVO_IOS_DOWNLOAD_URL} isExternal>
              <Button variant='solid' colorScheme='teal' minW={'124px'}>
                iPhone
              </Button>
            </Link>
            <Link href={FEDI_BRAVO_APK_DOWNLOAD_URL} isExternal>
              <Button variant='solid' colorScheme='teal' minW={'124px'}>
                APK
              </Button>
            </Link>
          </ButtonGroup>
        </Flex>
      </Flex>
    </SectionTemplate>
  );
});
