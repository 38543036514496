import React, { useState, useCallback } from 'react';
import {
  Button,
  Flex,
  FormControl,
  Box,
  FormHelperText,
  ButtonGroup,
  useTheme,
  Text,
  useToast,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import {
  Chama,
  ChamaTxState,
  ChamaTxType,
  Currency,
  User,
} from '@bitsacco/types';
import { AmountInputGroup } from '../InputGroups';
import { useApi, useFx } from '../Providers';
import { TemplateModal } from '../TemplateModal';
import { getGroupBalanceMsats, recordChamaTransaction } from '../../services';
import { TOAST_TIMEOUT_MS } from '../../configs';

interface RequestWithdrawModalProps {
  chama: Chama;
  user: User;
  isOpen: boolean;
  onClose: () => void;
}

export const RequestWithdrawModal = React.memo(function RequestWithdrawModal({
  chama,
  user,
  isOpen,
  onClose,
}: RequestWithdrawModalProps): JSX.Element {
  const { bitsacco } = useApi();
  const { milliSatsToKes, kesToMilliSats } = useFx();

  const theme = useTheme();
  const toast = useToast();

  const [amount, setAmount] = useState<number>(0);
  const [requestError, setRequestError] = useState<string>('');

  const closeModal = useCallback(() => {
    setAmount(0);
    setRequestError('');
    onClose();
  }, [onClose, setAmount, setRequestError]);

  const requestWithdrawal = useCallback(() => {
    (async () => {
      try {
        const fx = kesToMilliSats(amount);

        if (fx.r === Currency.BTC) {
          const tx = await recordChamaTransaction(bitsacco, chama, user, {
            id: uuidv4(),
            type: ChamaTxType.Withdrawal,
            amount: fx.n,
            state: ChamaTxState.Pending,
            meta: {
              chama: chama.id,
              user: user.id,
              description: `Withdrawal of ${amount} ${Currency.KES}`,
              timestamp: new Date(),
            },
            approvals: [],
          });

          if (tx) {
            console.log('sent withdrawal request');
            toast({
              title: 'Success',
              description: 'Sent withdrawal request',
              status: 'success',
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });

            return closeModal();
          }
        }

        throw new Error('failed to request withdrawal');
      } catch (e) {
        console.log(e);
        setRequestError(`${e}`);
        toast({
          title: 'Error',
          description: 'Failed to request withdrawal',
          status: 'error',
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      }
    })();
  }, [bitsacco, chama, user, amount, toast, setRequestError]);

  const updateAmount = useCallback(
    (amount: number) => {
      const available = getGroupBalanceMsats(chama.members);
      const fx = milliSatsToKes(available);
      if (fx.r === Currency.KES && amount > fx.n) {
        setAmount(amount);
        setRequestError(`The maximum you can withdraw is ${fx.n}`);
      } else {
        setAmount(amount);
        setRequestError('');
      }
    },
    [chama, setAmount, setRequestError]
  );

  const getModalHeader = useCallback(() => {
    return <Text>Request withdrawal from {chama.name}</Text>;
  }, [chama]);

  const getModalBody = useCallback(() => {
    return (
      <Flex flexDirection='column' gap='5' h='100%' justify='center'>
        <FormControl>
          <Box pb='5'>
            <AmountInputGroup
              amount={amount}
              setAmount={updateAmount}
              getFormHelperText={(amountError?: string) => {
                return amountError ? (
                  <FormHelperText color='red.300'>{amountError}</FormHelperText>
                ) : (
                  <></>
                );
              }}
            />
          </Box>
          {requestError ? (
            <FormHelperText color='red.300'>{requestError}</FormHelperText>
          ) : (
            <FormHelperText>
              we will send a withdrawal request to all the chama admins
            </FormHelperText>
          )}
        </FormControl>
      </Flex>
    );
  }, [amount, requestError, theme, setAmount]);

  const getModalFooter = useCallback(() => {
    return (
      <ButtonGroup
        w='100%'
        display='flex'
        flexDirection='row'
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        justifyContent='space-between'
        spacing={{ base: '0', lg: '4' }}
        gap={'2'}
      >
        <Button
          variant='solid'
          colorScheme='green'
          isDisabled={amount < 1 || !!requestError}
          onClick={requestWithdrawal}
        >
          Request Withdrawal
        </Button>
        <Button onClick={closeModal} variant='outline' colorScheme='red'>
          Cancel
        </Button>
      </ButtonGroup>
    );
  }, [amount, requestError, closeModal]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
