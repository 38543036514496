import React, { useCallback } from 'react';
import { SectionProps } from './types';
import { CopyIcon } from '@chakra-ui/icons';
import { Heading, Flex, IconButton, Text } from '@chakra-ui/react';
import { AddressInputGroup } from '../InputGroups';

interface AccountAddressProps extends SectionProps {
  address?: string;
  proposeAddress: (name: string) => void;
  copyAddress: () => void;
}

export const AccountAddress = React.memo(function AccountAddress({
  address,
  proposeAddress,
  copyAddress,
  edit,
  bgBox,
  bdBox,
}: AccountAddressProps) {
  const renderAddress = useCallback(() => {
    if (edit) {
      return (
        <AddressInputGroup
          address={address || ''}
          setAddress={proposeAddress}
        />
      );
    }

    if (address) {
      return (
        <>
          <Text>{address}</Text>
          <IconButton
            aria-label='Copy Address'
            icon={<CopyIcon />}
            onClick={copyAddress}
          />
        </>
      );
    }

    return <Text>{'example@bitsacco.com'}</Text>;
  }, [edit, address, proposeAddress, copyAddress]);

  return (
    <>
      <Heading as='h2' size='md'>
        Address
      </Heading>

      <Flex
        align='center'
        justify='space-between'
        p={4}
        gap={4}
        bg={bgBox}
        border={`2px solid ${bdBox}`}
        borderRadius='md'
      >
        {renderAddress()}
      </Flex>
    </>
  );
});
