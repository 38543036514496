import React, { useEffect, useState } from 'react';
import {
  User,
  ChamaTx,
  FindChamaTx,
  ChamaTxType,
  ChamaTxState,
} from '@bitsacco/types';
import {
  Flex,
  Heading,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Text,
  Button,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { FetchHeadshot } from '../Headshot';
import { useApi } from '../Providers';

interface ChamaCardActivityProps {
  user: User;
  chamaId: string;
}

const ShortRowLimit = 4;

export const ChamaCardActivity = React.memo(function ChamaCardActivity({
  user,
  chamaId,
}: ChamaCardActivityProps) {
  const { bitsacco } = useApi();
  const [transactions, setTransactions] = useState<ChamaTx[]>([]);
  const [showAll, setShowAll] = useState(false);

  const displayedTransactions = showAll
    ? transactions
    : transactions.slice(0, ShortRowLimit);

  useEffect(() => {
    (async () => {
      try {
        const txs = await bitsacco.request<ChamaTx[], FindChamaTx>(
          'POST',
          `/chama/tx/all`,
          {
            id: chamaId,
          }
        );

        txs && setTransactions(txs.reverse());
      } catch (error) {
        console.error('error fetching transactions:', error);
      }
    })();
  }, [bitsacco, chamaId]);

  return (
    <Flex direction='column' gap={4}>
      <Heading size='sm' textTransform='uppercase'>
        Activity
      </Heading>

      <Box
        px={{ base: '', md: '3' }}
        gap={{ base: '6', lg: '6' }}
        overflowX='scroll'
        css={{ scrollbarWidth: 'none' }}
      >
        {displayedTransactions.length > 0 ? (
          <>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Description</Th>
                  <Th isNumeric>Amount (Sats)</Th>
                  <Th>Member</Th>
                  <Th>Type</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayedTransactions.map((tx) => (
                  <Tr key={tx.id}>
                    <Td>{format(new Date(tx.meta.timestamp), 'PPpp')}</Td>
                    <Td>{tx.meta.description}</Td>
                    <Td isNumeric>{tx.amount.toFixed(2)}</Td>
                    <Td>
                      <FetchHeadshot user={user} id={tx.meta.user} />
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={getTxTypeColor(tx.type)}
                        variant='outline'
                        borderRadius={5}
                      >
                        {tx.type}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={getTxStateColor(tx.state)}
                        borderRadius={5}
                      >
                        {tx.state}
                      </Badge>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {transactions.length > ShortRowLimit && (
              <Flex alignItems='center' justifyContent='end' fontFamily='body'>
                <Button mt={4} onClick={() => setShowAll(!showAll)}>
                  {showAll ? 'Show Less' : 'Show More'}
                </Button>
              </Flex>
            )}
          </>
        ) : (
          <Text>no chama activity found</Text>
        )}
      </Box>
    </Flex>
  );
});

const getTxTypeColor = (type: ChamaTxType) => {
  switch (type) {
    case ChamaTxType.Deposit:
      return 'green';
    case ChamaTxType.Withdrawal:
      return 'red';
  }
};

const getTxStateColor = (state: ChamaTxState) => {
  switch (state) {
    case ChamaTxState.Pending:
      return 'teal';
    case ChamaTxState.Processing:
      return 'teal';
    case ChamaTxState.Retry:
      return 'teal';
    case ChamaTxState.Failed:
      return 'red';
    case ChamaTxState.Complete:
      return 'green';
  }
};
