import { WebLNProvider } from '@webbtc/webln-types';

export const enableWebLN = async () => {
  await window.webln?.enable();
};

const WeblnProviderTimeout = 3000;
const WeblnProviderInterval = 100;

export const detectWebLNProvider = async (
  timeout: number = WeblnProviderTimeout
): Promise<WebLNProvider | null> => {
  let handled = false;

  return new Promise((resolve) => {
    if (window.webln) {
      handleWebLN();
    } else {
      document.addEventListener('webln:ready', handleWebLN, { once: true });

      let i = 0;
      const checkInterval = setInterval(function () {
        if (window.webln || i >= timeout / WeblnProviderInterval) {
          handleWebLN();
          clearInterval(checkInterval);
        }
        i++;
      }, WeblnProviderInterval);
    }

    function handleWebLN() {
      if (handled) {
        return;
      }
      handled = true;

      document.removeEventListener('webln:ready', handleWebLN);
      const webln = window.webln;

      if (webln) {
        enableWebLN()
          .then(() => {
            resolve(webln);
          })
          .catch((e) => {
            console.error(e);
            resolve(null);
          });
      } else {
        resolve(null);
      }
    }
  });
};
