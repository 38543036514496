import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Text, Heading, useDisclosure } from '@chakra-ui/react';
import {
  FedimintClient,
  FedimintClientBuilder,
} from 'fedimint-ts/dist/FedimintClient.js';
import { Chama } from '@bitsacco/types';
import { useApi, ChamaModal, useAuth, ChamaCard } from '../components';

import {
  CLIENTD_BASE_URL,
  CLIENTD_PASS,
  FEDERATION_ID,
  GATEWAY_ID,
} from '../configs';
import { digitizePhone } from '../utils';

export const Chamas = React.memo(function Chamas(): JSX.Element {
  const { bitsacco } = useApi();
  const { user } = useAuth();

  const [chamaClient, setChamaClient] = useState<FedimintClient | undefined>();
  const [chamas, setChamas] = useState<Chama[]>([]);

  const [fetchChamas, setFetchChamas] = useState<boolean>(true);

  const {
    isOpen: showChamaModal,
    onOpen: onOpenChamaModal,
    onClose: onCloseChamaModal,
  } = useDisclosure();

  useEffect(() => {
    const builder = new FedimintClientBuilder();
    builder.setBaseUrl(CLIENTD_BASE_URL);
    builder.setPassword(CLIENTD_PASS);
    builder.setActiveFederationId(FEDERATION_ID);

    const client: FedimintClient = builder.build();
    client.setActiveGatewayId(GATEWAY_ID);

    setChamaClient(client);
  }, []);

  useEffect(() => {
    if (!user || !fetchChamas) {
      return;
    }

    (async () => {
      try {
        const chamas = await bitsacco.request<Chama[], undefined>(
          'GET',
          '/chama/all'
        );

        if (chamas) {
          setChamas(
            chamas.filter((chama) =>
              Object.keys(chama.members).includes(digitizePhone(user.id))
            )
          );
          setFetchChamas(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [bitsacco, user, fetchChamas, setFetchChamas, setChamas]);

  const closeChamaModal = useCallback(
    (chama?: Chama) => {
      if (chama) {
        setChamas(
          chamas.map((c) => {
            if (c.id === chama.id) {
              return chama;
            }
            return c;
          })
        );
      }
      setFetchChamas(true);
      onCloseChamaModal();
    },
    [chamas, setChamas, onCloseChamaModal, setFetchChamas]
  );

  return (
    <>
      <Flex direction='column' flexGrow={1} basis='100%' gap='5' p='5' pb='20'>
        <Flex
          mt='10'
          p={'5'}
          gap={'4'}
          direction='row'
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          align='center'
          alignItems={'center'}
          justifyItems={'center'}
          justify='space-between'
        >
          <Flex direction='row' align='center' justify='center'>
            <Heading size='md'>CHAMAS</Heading>
            <Text p='3'>|</Text>
            <Text>manage funds together with friends and family</Text>
          </Flex>
          <Button
            variant='outline'
            colorScheme='green'
            height={'35px'}
            onClick={onOpenChamaModal}
          >
            + create
          </Button>
        </Flex>
        <Flex direction='column' gap='10'>
          {user &&
            chamaClient &&
            chamas.map((chama, idx) => {
              return (
                <ChamaCard
                  key={idx}
                  user={user}
                  chama={chama}
                  chamaClient={chamaClient}
                  setFetchChamas={setFetchChamas}
                />
              );
            })}
        </Flex>
      </Flex>
      {user && (
        <ChamaModal
          user={user}
          chama={undefined}
          isOpen={showChamaModal}
          onClose={closeChamaModal}
        />
      )}
    </>
  );
});
