import {
  Chama,
  ChamaMembers,
  ChamaTx,
  UpdateChama,
  User,
} from '@bitsacco/types';
import { BitsaccoApi } from '../Api';

export const updateChamaMemberBalance = async (
  bitsacco: BitsaccoApi,
  chama: Chama,
  user: User,
  deposit: number,
  withdrawal: number,
  penalty: number
): Promise<Chama> => {
  try {
    const member = chama.members[user.id];
    if (!member) {
      throw 'user is not a member of this chama';
    }

    const contributions = member.contributions + (deposit || 0);
    const withdrawals = member.withdrawals + (withdrawal || 0);
    const penalties = member.penalties + (penalty || 0);

    const updated = await bitsacco.request<Chama, UpdateChama>(
      'PATCH',
      '/chama/update',
      {
        id: chama.id,
        updates: {
          members: {
            [user.id]: {
              ...member,
              contributions,
              withdrawals,
              penalties,
            },
          },
        },
      }
    );

    if (!updated) {
      throw 'failed to record user contribution';
    }

    return updated;
  } catch (e) {
    console.error(e);
    return chama;
  }
};

export const recordChamaTransaction = async (
  bitsacco: BitsaccoApi,
  chama: Chama,
  user: User,
  transaction: ChamaTx
): Promise<ChamaTx | undefined> => {
  try {
    const member = chama.members[user.id];
    if (!member) {
      throw 'user is not a member of this chama';
    }

    const tx = await bitsacco.request<ChamaTx, ChamaTx>(
      'POST',
      `/chama/tx/create`,
      transaction
    );

    if (!tx) {
      throw 'failed to record chama transaction';
    }

    return tx;
  } catch (e) {
    // TODO: add telemetry
    console.error(e);
  }
};

export const getUserBalanceMsats = (
  chamaMembers: ChamaMembers,
  userId: string
) => {
  const member = chamaMembers[userId];

  if (!member) {
    throw new Error('User is not a member of this chama');
  }

  return member.contributions;
};

export const getGroupBalanceMsats = (chamaMembers: ChamaMembers) => {
  return Object.values(chamaMembers).reduce(
    (acc, member) => acc + member.contributions,
    0
  );
};
