import React, { useCallback, useState } from 'react';
import { Button, ButtonGroup, Text, Flex, useToast } from '@chakra-ui/react';
import { RecoverUser, User } from '@bitsacco/types';
import { TOAST_TIMEOUT_MS } from '../../configs';
import { digitizePhone, isValidPhone, isValidPin } from '../../utils';
import { TemplateModal } from '../TemplateModal';
import { useApi } from '../Providers';
import { PinStack } from '../PinStack';

interface ResetPinModalProps {
  phone: string;
  isOpen: boolean;
  onClose: () => void;
}

enum ModalView {
  AlertReset,
  ResetPin,
}

export const ResetPinModal = React.memo(function ResetPinModal({
  phone,
  isOpen,
  onClose,
}: ResetPinModalProps): JSX.Element {
  const { bitsacco } = useApi();

  const [pin, setPin] = useState<string>('');

  const [authError, setAuthError] = useState<string>('');
  const [modalView, setModalView] = useState<ModalView>(ModalView.AlertReset);

  const toast = useToast();

  const closeModal = useCallback(() => {
    setPin('');
    setModalView(ModalView.AlertReset);
    setAuthError('');
    onClose();
  }, [onClose, setPin, setModalView, setAuthError]);

  const recoverUser = useCallback(() => {
    if (!isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, RecoverUser>(
          'POST',
          '/user/recover',
          {
            phone: digitizePhone(phone),
            pin,
          }
        );

        if (user) {
          toast({
            title: 'Success',
            description: `Updated account pin.`,
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
          return closeModal();
        }

        throw 'Failed to recover account';
      } catch (e) {
        setAuthError(`${e}`);
      }
    })();
  }, [bitsacco, phone, pin, toast, setAuthError, closeModal]);

  const getModalHeader = useCallback(() => {
    switch (modalView) {
      case ModalView.AlertReset:
        return (
          <Flex align='center' justify='center'>
            <Text>Confirm Pin Reset</Text>
          </Flex>
        );
      case ModalView.ResetPin:
        return (
          <Flex align='center' justify='center'>
            <Text>Reset Pin</Text>
          </Flex>
        );
    }
  }, [modalView]);

  const setPhone = (phone: string) => console.log(phone);

  const getModalBody = useCallback(() => {
    switch (modalView) {
      case ModalView.AlertReset:
        return (
          <Flex align='center' justify='center'>
            <Text>
              Are you sure you want to{' '}
              <span
                style={{
                  textTransform: 'uppercase',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: 'teal',
                }}
              >
                reset
              </span>{' '}
              the pin securing your account?
            </Text>
          </Flex>
        );
      case ModalView.ResetPin:
        return (
          <Flex align='center' justify='center'>
            <PinStack
              {...{
                pin,
                phone,
                authError,
                setPin,
                setPhone,
                setAuthError,
                recoverUser,
              }}
            />
          </Flex>
        );
    }
  }, [modalView, pin, phone, authError, setPin, setAuthError, recoverUser]);

  const getModalFooter = useCallback(() => {
    switch (modalView) {
      case ModalView.AlertReset:
        return (
          <ButtonGroup
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Button onClick={closeModal} variant='outline' colorScheme='teal'>
              Cancel
            </Button>
            <Button
              onClick={() => setModalView(ModalView.ResetPin)}
              variant='solid'
              colorScheme='teal'
            >
              Continue
            </Button>
          </ButtonGroup>
        );
      case ModalView.ResetPin:
        return (
          <ButtonGroup
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Button onClick={closeModal} variant='outline' colorScheme='red'>
              Cancel
            </Button>
          </ButtonGroup>
        );
    }
  }, [modalView, setModalView, closeModal]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={closeModal}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
