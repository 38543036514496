import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
  Flex,
  Box,
  FormControl,
  FormHelperText,
  Text,
  Divider,
  AbsoluteCenter,
  useToast,
} from '@chakra-ui/react';
import { WebLNNode, WebLNProvider } from '@webbtc/webln-types';
import {
  InfoResponse,
  LightningInvoiceResponse,
} from 'fedimint-ts/dist/types.js';
import QRCode from 'qrcode.react';
import {
  MpesaTransaction,
  RequestMpesa,
  MpesaInvoice,
  MpesaTractactionState,
} from '@bitsacco/types';
import { DEBOUNCE_DELAY_MS, TOAST_TIMEOUT_MS } from '../configs';
import { cancellablePromise, digitizePhone, useDebounce } from '../utils';
import { AmountInputGroup, PhoneInputGroup } from './InputGroups';
import { useApi, useFx } from './Providers';
import { TemplateModal } from './TemplateModal';
import { TransactionState, TransactionStateTracker } from './TransactionState';

export interface DepositClient {
  createInvoice: (
    msats: number,
    memo: string
  ) => Promise<LightningInvoiceResponse>;
  awaitInvoice: (operationId: string) => Promise<InfoResponse>;
}

export interface DepositTarget {
  id: string;
  name: string;
  phone: string;
}

interface DepositModalProps {
  depositClient: DepositClient;
  depositTarget: DepositTarget;
  isOpen: boolean;
  onClose: () => void;
  onTransactionComplete: (txid: string, amount: number) => void;
}

export const DepositModal = function DepositModal({
  depositClient,
  depositTarget,
  isOpen,
  onClose,
  onTransactionComplete,
}: DepositModalProps): JSX.Element {
  const theme = useTheme();
  const { kesToMilliSats } = useFx();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [amount, setAmount] = useState<number>(0);
  const [debounced] = useDebounce<number>(amount, DEBOUNCE_DELAY_MS);
  const [amountMsats, setAmountMsats] = useState<number>(0);
  const [invoice, setInvoice] = useState<LightningInvoiceResponse>();

  const [depositState, setTransactionState] = useState(TransactionState.Create);

  useEffect(() => {
    if (!depositClient || !debounced) {
      setInvoice(undefined);
      return;
    }

    const { promise, cancel } = cancellablePromise<void>(
      (async () => {
        const msats = kesToMilliSats(debounced).n;

        // TODO: deduct tx costs, or add fee to mpesa request
        const invoice = await depositClient.createInvoice(
          msats,
          `${depositTarget.phone}:${depositTarget.id}`
        );

        setAmountMsats(msats);
        setInvoice(invoice);

        const info = await depositClient.awaitInvoice(invoice.operationId);

        console.log(info);
        onTransactionComplete(invoice.operationId, msats);
        setTransactionState(TransactionState.Complete);

        return;
      })()
    );

    promise.catch((e) => {
      console.error(e);
      setTransactionState(TransactionState.Failed);
    });

    return cancel;
  }, [
    depositClient,
    depositTarget,
    debounced,
    onTransactionComplete,
    kesToMilliSats,
  ]);

  const restartDeposit = useCallback(() => {
    setTransactionState(TransactionState.Create);
    setAmount(0);
    setAmountMsats(0);
    setInvoice(undefined);
  }, [setTransactionState, setAmount, setInvoice]);

  const closeDialogue = useCallback(() => {
    restartDeposit();
    onClose();
  }, [restartDeposit, onClose]);

  const getDepositActions = useCallback(() => {
    if (
      depositState === TransactionState.Failed ||
      depositState === TransactionState.Processing ||
      depositState === TransactionState.Complete
    ) {
      return (
        <>
          <Button
            onClick={restartDeposit}
            variant='outline'
            colorScheme='green'
          >
            Start Over
          </Button>
          <Button onClick={closeDialogue} variant='outline' colorScheme='red'>
            Close
          </Button>
        </>
      );
    }

    return (
      <Button onClick={closeDialogue} variant='outline' colorScheme='red'>
        Cancel
      </Button>
    );
  }, [depositState, restartDeposit, closeDialogue]);

  const getModalHeader = useCallback(() => {
    return <Text>Deposit funds to {depositTarget.name}</Text>;
  }, [depositTarget]);

  const getModalBody = useCallback(() => {
    return (
      <Tabs
        isFitted
        colorScheme={theme.colors.teal[50]}
        onChange={(index) => setTabIndex(index)}
        defaultIndex={tabIndex}
      >
        <TabList mb='1em'>
          <Tab>Use Mpesa</Tab>
          <Tab>Use Lightning</Tab>
        </TabList>
        <TabPanels minH='16em'>
          <TabPanel>
            <Flex
              flexDirection='column'
              gap='5'
              h='100%'
              justify='center'
              align='center'
            >
              <MpesaDeposit
                amount={amount}
                amountMsats={amountMsats}
                invoice={invoice}
                depositTarget={depositTarget}
                depositState={depositState}
                updateAmount={setAmount}
                updateTransactionState={setTransactionState}
                onTransactionComplete={onTransactionComplete}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex flexDirection='column' gap='5' h='100%' justify='center'>
              <LightningDeposit
                amount={amount}
                invoice={invoice}
                depositState={depositState}
                updateAmount={setAmount}
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }, [
    amount,
    amountMsats,
    invoice,
    depositTarget,
    depositState,
    setAmount,
    setTransactionState,
    onTransactionComplete,
    theme,
    tabIndex,
    setTabIndex,
  ]);

  const getModalFooter = useCallback(() => {
    return <ButtonGroup spacing='2'>{getDepositActions()}</ButtonGroup>;
  }, [getDepositActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
};

interface MpesaDepositProps {
  amount: number;
  amountMsats: number;
  invoice?: LightningInvoiceResponse;
  depositTarget: DepositTarget;
  depositState: TransactionState;
  updateAmount: (amount: number) => void;
  updateTransactionState: (state: TransactionState) => void;
  onTransactionComplete: (txid: string, amount: number) => void;
}

const MpesaDeposit = React.memo(function MpesaDeposit({
  amount,
  amountMsats,
  invoice,
  depositTarget,
  depositState,
  updateAmount,
  updateTransactionState,
  onTransactionComplete,
}: MpesaDepositProps) {
  const { swap } = useApi();

  const [phone, setPhone] = useState<string>(depositTarget.phone);

  const [depositRequest, setDepositRequest] = useState<
    MpesaInvoice | undefined
  >();

  const [depositError, setDepositError] = useState<string>();

  useEffect(() => {
    if (!depositRequest) {
      return;
    }

    const interval = setInterval(async () => {
      // Create new api calls otherwise
      try {
        if (!invoice) {
          return;
        }

        const deposits = await swap.request<MpesaTransaction[], { id: string }>(
          'POST',
          '/mpesa/find',
          { id: depositRequest.invoice_id }
        );

        if (deposits && deposits.length === 1) {
          switch (deposits[0].state) {
            case MpesaTractactionState.Complete:
              onTransactionComplete(depositRequest.invoice_id, amountMsats);
              updateTransactionState(TransactionState.Complete);
              clearInterval(interval);
              break;
            case MpesaTractactionState.Processing:
              updateTransactionState(TransactionState.Processing);
              break;
            case MpesaTractactionState.Failed:
              updateTransactionState(TransactionState.Failed);
              clearInterval(interval);
              break;
          }
        }
      } catch (e) {
        console.error(e);
        // stop further polling on error
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [
    swap,
    depositRequest,
    amountMsats,
    invoice,
    updateTransactionState,
    onTransactionComplete,
  ]);

  const depositFromMpesa = useCallback(() => {
    (async () => {
      if (!amount || !phone || !invoice) {
        return;
      }

      try {
        const payload: RequestMpesa = {
          lightning: invoice.invoice,
          userId: depositTarget.phone,
          chamaId: depositTarget.id,
          phone: digitizePhone(phone),
          amount: Number(amount),
        };
        console.log(payload);

        const request = await swap.request<MpesaInvoice, RequestMpesa>(
          'POST',
          '/mpesa/deposit',
          payload
        );

        if (request) {
          updateAmount(0);
          setDepositRequest(request);
          updateTransactionState(TransactionState.Pending);
        }
      } catch (e) {
        console.error(e);
        setDepositError(`Deposit Failed: ${e}`);
      }
    })();
  }, [
    swap,
    depositTarget,
    amount,
    phone,
    invoice,
    setDepositRequest,
    updateAmount,
    updateTransactionState,
  ]);

  const getFormHelperText = useCallback(() => {
    if (depositError) {
      return <FormHelperText color='red.300'>{depositError}</FormHelperText>;
    }

    return (
      <FormHelperText>
        we will send an mpesa request to the phone number you enter above
      </FormHelperText>
    );
  }, [depositError]);

  return depositState === TransactionState.Create ? (
    <Flex flexDirection='column' gap='5' h='100%' justify='center'>
      <FormControl>
        <Box pb='5'>
          <AmountInputGroup
            amount={amount}
            setAmount={updateAmount}
            getFormHelperText={(amountError?: string) => {
              return amountError ? (
                <FormHelperText color='red.300'>{amountError}</FormHelperText>
              ) : (
                <></>
              );
            }}
          />
        </Box>
        <Box pb='2'>
          <PhoneInputGroup phone={phone} setPhone={setPhone} />
        </Box>
        {getFormHelperText()}
      </FormControl>
      <Button onClick={depositFromMpesa} variant='solid' colorScheme='green'>
        Send Request
      </Button>
    </Flex>
  ) : (
    <TransactionStateTracker
      transactionState={depositState}
      progress={{
        isIndeterminate: true,
        thickness: '4px',
        color: 'green.300',
        size: '3em',
      }}
      icon={{ boxSize: '4em' }}
    />
  );
});

interface LightningDepositProps {
  amount: number;
  invoice?: LightningInvoiceResponse;
  depositState: TransactionState;
  updateAmount: (amount: number) => void;
}

const LightningDeposit = React.memo(function LightningDeposit({
  amount,
  invoice,
  depositState,
  updateAmount,
}: LightningDepositProps) {
  const { webln } = useApi();
  const toast = useToast();

  const copyInvoice = useCallback(
    (invoice: string): void => {
      if (navigator?.clipboard?.writeText) {
        navigator.clipboard.writeText(invoice).then(
          () => {
            console.log('Invoice copied');
            toast({
              title: 'Success',
              description: 'Copied invoice to clipboard',
              status: 'success',
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          },
          (err) => {
            console.error('Failed to copy invoice', err);
            toast({
              title: 'Error',
              description: 'Failed to copy invoice',
              status: 'error',
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          }
        );
      } else {
        console.error('Clipboard API not supported');
      }
    },
    [toast]
  );

  return depositState === TransactionState.Create ? (
    <>
      <FormControl>
        <Box>
          <AmountInputGroup
            amount={amount}
            setAmount={updateAmount}
            getFormHelperText={(amountError?: string) => {
              return amountError ? (
                <FormHelperText color='red.300'>{amountError}</FormHelperText>
              ) : (
                <></>
              );
            }}
          />
        </Box>
        {invoice && (
          <>
            {webln && (
              <>
                <WebLnDeposit webln={webln} amount={amount} invoice={invoice} />
                <Box position='relative' padding='6'>
                  <Divider colorScheme='green' size='md' />
                  <AbsoluteCenter bg='white' px='5'>
                    <strong>OR</strong>
                  </AbsoluteCenter>
                </Box>
              </>
            )}
            <Flex
              flexDirection='row'
              gap='5'
              h='100%'
              justify='center'
              align='center'
              pt='6'
            >
              <QRCode
                bgColor='#FFFFFF'
                fgColor='#000000'
                value={invoice.invoice}
                size={200}
              />
              <FormHelperText>
                This is a Bitcoin Lightning invoice <br />
                Scan it with a lightning wallet <br />
                to pay
              </FormHelperText>
            </Flex>
          </>
        )}
        {!invoice && (
          <FormHelperText pt='2'>
            you will pay this amount over the bitcoin lightning network
          </FormHelperText>
        )}
      </FormControl>

      {invoice && (
        <Button
          onClick={() => copyInvoice(invoice.invoice)}
          variant='solid'
          colorScheme='green'
        >
          Copy Invoice
        </Button>
      )}
    </>
  ) : (
    <TransactionStateTracker
      transactionState={depositState}
      progress={{
        isIndeterminate: true,
        thickness: '4px',
        color: 'green.300',
        size: '3em',
      }}
      icon={{ boxSize: '4em' }}
    />
  );
});

interface WebLnDepositProps {
  webln: WebLNProvider;
  amount: number;
  invoice?: LightningInvoiceResponse;
}

const WebLnDeposit = React.memo(function WebLnDeposit({
  webln,
  amount,
  invoice,
}: WebLnDepositProps) {
  const [node, setNode] = useState<WebLNNode>();

  const [depositError, setDepositError] = useState<string>();

  useEffect(() => {
    webln
      .getInfo()
      .then((resp) => {
        setNode(resp.node);
      })
      .catch(console.error);
  }, [webln]);

  const depositFromWebln = useCallback((): void => {
    (async () => {
      if (!amount || !invoice) {
        return;
      }

      try {
        const res = await webln.sendPayment(invoice.invoice);
        if (!res) {
          throw '';
        }
        console.log('Preimage : ', res.preimage);
      } catch (e) {
        setDepositError(`Deposit Failed: ${e}`);
        console.error(e);
      }
    })();
  }, [webln, amount, invoice]);

  return (
    <Flex flexDirection='column' gap='5' h='100%' justify='center' pt='6'>
      <Text>We detected a Lightning wallet</Text>
      {depositError && (
        <FormControl w='100%'>
          <FormHelperText color='red.300' mt='-2'>
            {depositError}
          </FormHelperText>
        </FormControl>
      )}
      <Button onClick={depositFromWebln} variant='solid' colorScheme='green'>
        Deposit from {node?.alias || 'Wallet'}
      </Button>
    </Flex>
  );
});
