import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WebLNProvider } from '@webbtc/webln-types';
import { Currency, FXID, FxRate, User } from '@bitsacco/types';
import {
  setSessionValue,
  SESSION_USER_KEY,
  removeSessionValue,
  detectWebLNProvider,
} from '../services';
import { BitsaccoApi, SwapApi } from '../Api';

interface ApiContextValue {
  bitsacco: BitsaccoApi;
  swap: SwapApi;
  webln: WebLNProvider | null;
}

const ApiContext = createContext<ApiContextValue>({
  bitsacco: new BitsaccoApi(),
  swap: new SwapApi(),
  webln: null,
});

export const ApiProvider = React.memo(function ApiProvider({
  auth,
  children,
}: {
  auth: () => void;
  children: React.ReactNode;
}): JSX.Element {
  const bitsacco = useMemo(() => new BitsaccoApi(auth), [auth]);
  const swap = useMemo(() => new SwapApi(auth), [auth]);
  const [webln, setWebln] = useState<WebLNProvider | null>(null);

  // WebLN
  useEffect(() => {
    (async () => {
      const webln = await detectWebLNProvider();
      webln && setWebln(webln);
    })();
  }, [setWebln]);

  return (
    <ApiContext.Provider
      value={{
        bitsacco,
        swap,
        webln,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
});

interface AuthContextValue {
  user: User | null;
  login: (user: User, redirect?: string) => void;
  logout: () => void;
  nextRedirect: (redirect?: string) => void;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: (user: User, redirect?: string) =>
    console.log(`login: ${user} and redirecting to ${redirect}`),
  logout: () => console.log('logout'),
  nextRedirect: (redirect?: string) =>
    console.log(`redirecting to ${redirect}`),
});

export const AuthProvider = React.memo(function UserProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [user, setUser] = useState<User | null>(null);
  const [next, setNext] = useState<string | undefined>();

  const login = useCallback(
    (user: User, redirect?: string) => {
      setUser(user);
      setSessionValue(SESSION_USER_KEY, JSON.stringify(user));
      navigate(redirect || next || state?.from || '/', {
        replace: true,
      });
    },
    [next, state, setUser, navigate]
  );

  const logout = useCallback(() => {
    setUser(null);
    setNext(undefined);
    removeSessionValue(SESSION_USER_KEY);
    navigate('/');
  }, [setUser, navigate]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        nextRedirect: setNext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

interface FxContextValue {
  kesToMilliSats: (kesAmount: number) => { n: number; r: Currency };
  milliSatsToKes: (milliSatsAmount: number) => {
    n: number;
    r: Currency;
  };
}

export const FxContext = createContext<FxContextValue>({
  kesToMilliSats: (kesAmount: number) => {
    return { n: kesAmount, r: Currency.KES };
  },
  milliSatsToKes: (milliSatsAmount: number) => {
    return { n: milliSatsAmount, r: Currency.KES };
  },
});

export const FxProvider = React.memo(function FxProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { bitsacco } = useApi();
  const [fx, setFx] = useState<FxRate | null>(null);

  const fetchRates = useCallback(async () => {
    try {
      const rates = await bitsacco.request<FxRate[], void>('GET', `/fx/rates`);
      if (rates && rates.length > 0) {
        const fx = rates.find((r) => r.id === FXID);
        fx && setFx(fx);
      }
    } catch (error) {
      console.error('Failed to fetch rates:', error);
    }
  }, [bitsacco]);

  useEffect(() => {
    fetchRates();

    const intervalId = setInterval(() => {
      fetchRates();
      // Fetch rates every 10 minutes
    }, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [fetchRates]);

  const kesToMilliSats = useCallback(
    (kesAmount: number) => {
      if (fx === null) {
        return { n: kesAmount, r: Currency.KES };
      }

      return { n: Math.floor(kesAmount * fx.satrate * 1000), r: Currency.BTC };
    },
    [fx]
  );

  const milliSatsToKes = useCallback(
    (msatsAmount: number) => {
      if (fx === null) {
        return { n: msatsAmount, r: Currency.BTC };
      }

      return {
        n: Math.floor((msatsAmount * fx.kesrate) / 1000),
        r: Currency.KES,
      };
    },
    [fx]
  );

  return (
    <FxContext.Provider
      value={{
        kesToMilliSats,
        milliSatsToKes,
      }}
    >
      {children}
    </FxContext.Provider>
  );
});

export const useApi = (): ApiContextValue => useContext(ApiContext);
export const useAuth = (): AuthContextValue => useContext(AuthContext);
export const useFx = (): FxContextValue => useContext(FxContext);
