import React, { useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Center,
  Button,
  VStack,
  FormControl,
  FormHelperText,
  Flex,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import { CreateUser, LoginUser, RecoverUser, User } from '@bitsacco/types';
import { SESSION_USER_KEY, getSessionValue } from '../services';
import {
  useApi,
  useAuth,
  PhoneInputGroup,
  PinInputGroup,
  Headshot,
  PinStack,
} from '../components';
import {
  digitizePhone,
  getProfileLabel,
  isValidPhone,
  isValidPin,
} from '../utils';

export enum AuthExperience {
  Resume,
  Login,
  Signup,
  Recover,
}

export const Auth = React.memo(function Auth(): JSX.Element {
  const [sessionUser] = useState<string>(getSessionValue(SESSION_USER_KEY));
  const [phone, setPhone] = useState<string>('');
  const [authExperience, setAuthExperience] = useState<AuthExperience>(
    sessionUser ? AuthExperience.Resume : AuthExperience.Login
  );

  return (
    <ControlledAuth
      {...{ phone, setPhone, authExperience, setAuthExperience }}
    />
  );
});

interface ControlledAuthProps {
  phone: string;
  setPhone: (phone: string) => void;
  authExperience: AuthExperience;
  setAuthExperience: (exp: AuthExperience) => void;
}

export const ControlledAuth = React.memo(function ControlledAuth({
  phone,
  setPhone,
  authExperience,
  setAuthExperience,
}: ControlledAuthProps): JSX.Element {
  const [sessionUser] = useState<string>(getSessionValue(SESSION_USER_KEY));
  const [pin, setPin] = useState<string>('');

  const [authError, setAuthError] = useState<string>('');

  const updateAuthExperiece = useCallback(
    (exp: AuthExperience) => {
      setAuthError('');
      setPin('');
      setAuthExperience(exp);
    },
    [setAuthError, setPin, setAuthExperience]
  );

  const getAuthExperience = useCallback(() => {
    switch (authExperience) {
      case AuthExperience.Resume:
        try {
          const user: User = JSON.parse(sessionUser);
          return (
            <ResumeStack user={user} setAuthExperience={updateAuthExperiece} />
          );
        } catch (e) {
          console.error(e);
          updateAuthExperiece(AuthExperience.Login);
          return (
            <LoginStack
              pin={pin}
              phone={phone}
              authError={authError}
              setPin={setPin}
              setPhone={setPhone}
              setAuthError={setAuthError}
              setAuthExperience={updateAuthExperiece}
            />
          );
        }
      case AuthExperience.Login:
        return (
          <LoginStack
            pin={pin}
            phone={phone}
            authError={authError}
            setPin={setPin}
            setPhone={setPhone}
            setAuthError={setAuthError}
            setAuthExperience={updateAuthExperiece}
          />
        );
      case AuthExperience.Signup:
        return (
          <SignupStack
            pin={pin}
            phone={phone}
            authError={authError}
            setPin={setPin}
            setPhone={setPhone}
            setAuthError={setAuthError}
            setAuthExperience={updateAuthExperiece}
          />
        );
      case AuthExperience.Recover:
        return (
          <RecoverStack
            pin={pin}
            phone={phone}
            authError={authError}
            setPin={setPin}
            setPhone={setPhone}
            setAuthError={setAuthError}
            setAuthExperience={updateAuthExperiece}
          />
        );
    }
  }, [
    authExperience,
    sessionUser,
    pin,
    phone,
    authError,
    setPin,
    setPhone,
    setAuthError,
    updateAuthExperiece,
  ]);

  return (
    <Center pt='10'>
      <VStack>{getAuthExperience()}</VStack>
    </Center>
  );
});

interface ResumeStackProps {
  user: User;
  setAuthExperience: (exp: AuthExperience) => void;
}

const ResumeStack = ({ user, setAuthExperience }: ResumeStackProps) => {
  const { login } = useAuth();

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            Welcome Back
          </Text>
        </CardHeader>
        <CardBody>
          <Flex align='center' flexDir={'column'} gap={4}>
            <Skeleton size='lg' isLoaded={!!user}>
              <Headshot user={user} size='lg' />
            </Skeleton>
            <Text fontSize='xl'>{getProfileLabel(user)}</Text>
            <Button
              variant='solid'
              colorScheme='teal'
              onClick={() => login(user)}
              w='100%'
            >
              Continue
            </Button>
          </Flex>
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Login)}
        >
          Login
        </Button>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Signup)}
        >
          Signup
        </Button>
      </Flex>
    </VStack>
  );
};

interface AuthStackProps {
  pin: string;
  phone: string;
  authError: string;
  setPin: (pin: string) => void;
  setPhone: (phone: string) => void;
  setAuthError: (error: string) => void;
  setAuthExperience: (exp: AuthExperience) => void;
}

const LoginStack = ({
  pin,
  phone,
  authError,
  setPin,
  setPhone,
  setAuthError,
  setAuthExperience,
}: AuthStackProps) => {
  const { bitsacco } = useApi();
  const { login } = useAuth();

  const loginUser = useCallback(() => {
    if (!isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, LoginUser>(
          'POST',
          '/user/login',
          {
            phone: digitizePhone(phone),
            pin,
          }
        );

        if (user) {
          return login(user);
        }

        throw `login error`;
      } catch (e) {
        setAuthError(`${e}`);
        setPin('');
      }
    })();
  }, [bitsacco, phone, pin, login, setAuthError, setPin]);

  const showEnterPin = useCallback(() => {
    if (phone && isValidPhone(phone)) {
      return (
        <>
          <FormHelperText mt={'5'} mb={'2'}>
            Enter Pin
          </FormHelperText>
          <PinInputGroup
            pin={pin}
            setPin={(pin: string) => {
              setAuthError('');
              setPin(pin);
            }}
          />
        </>
      );
    }

    return <></>;
  }, [phone, pin, setPin, setAuthError]);

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            Log In
          </Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4}>
            <FormControl>
              <PhoneInputGroup
                phone={phone}
                setPhone={(phone: string) => {
                  setAuthError('');
                  setPhone(phone);
                }}
              />
              {showEnterPin()}
              {authError && (
                <FormHelperText color='red.300'>{authError}</FormHelperText>
              )}
            </FormControl>
            <Button
              variant='solid'
              colorScheme='teal'
              onClick={loginUser}
              isDisabled={!phone || !isValidPin(pin) || !!authError}
              w='100%'
            >
              Continue
            </Button>
          </VStack>
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Signup)}
        >
          Signup
        </Button>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Recover)}
        >
          Recover
        </Button>
      </Flex>
    </VStack>
  );
};

const SignupStack = ({
  pin,
  phone,
  authError,
  setPin,
  setPhone,
  setAuthError,
  setAuthExperience,
}: AuthStackProps) => {
  const { bitsacco } = useApi();

  const signupUser = useCallback(() => {
    if (!isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, CreateUser>(
          'POST',
          '/user/create',
          {
            phone: digitizePhone(phone),
            pin,
            nostr: null,
            profile: null,
          }
        );

        if (user) {
          setAuthError('');
          return;
        }

        throw 'failed to register user';
      } catch (e) {
        setAuthError(`${e}`);
      }
    })();
  }, [bitsacco, phone, pin, setAuthError]);

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            Sign Up
          </Text>
        </CardHeader>
        <CardBody>
          <PinStack
            {...{
              pin,
              phone,
              authError,
              setPin,
              setPhone,
              setAuthError,
              signupUser,
            }}
          />
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Login)}
        >
          Login
        </Button>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Recover)}
        >
          Recover
        </Button>
      </Flex>
    </VStack>
  );
};

const RecoverStack = ({
  pin,
  phone,
  authError,
  setPin,
  setPhone,
  setAuthError,
  setAuthExperience,
}: AuthStackProps) => {
  const { bitsacco } = useApi();
  const { login } = useAuth();

  const recoverUser = useCallback(() => {
    if (!isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, RecoverUser>(
          'POST',
          '/user/recover',
          {
            phone: digitizePhone(phone),
            pin,
          }
        );

        if (user) {
          return login(user);
        }

        throw 'Failed to recover account';
      } catch (e) {
        setAuthError(`${e}`);
      }
    })();
  }, [bitsacco, phone, pin, login, setAuthError]);

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            Recover Account
          </Text>
        </CardHeader>
        <CardBody>
          <PinStack
            {...{
              pin,
              phone,
              authError,
              setPin,
              setPhone,
              setAuthError,
              recoverUser,
            }}
          />
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Login)}
        >
          Login
        </Button>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Signup)}
        >
          Signup
        </Button>
      </Flex>
    </VStack>
  );
};
